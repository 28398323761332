import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Card from "@material-ui/core/Card";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Checkbox from '@material-ui/core/Checkbox'
import CardContent from "@material-ui/core/CardContent";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { db } from "../../firebase";

const emailMessage = {
  subject: 'Thank you for your interest in the BedRN beta!',
  text: 'Thank you for your interest in the BedRN beta! With your help, we hope to build BedRN into the ultimate tool for uniting hosts and travelers.\n\nBedRN is unlike other space listing platforms. We are exclusively focused on uniting property owners (and managers) with responsible and professional traveling medical professionals. During the BedRN beta, we will prompt you for feedback as you use the platform and may even periodically reach out by email for feedback.\n\nAs a reminder, we will not use the information you entered on the Beta Signup page for any reason other than contacting you once the BedRN beta goes live.',
  html: "<h1>Thank you for your interest in the BedRN beta!</h1><p>With your help, we hope to build BedRN into the ultimate tool for uniting hosts and travelers.</p><p>BedRN is unlike other space listing platforms. We are exclusively focused on uniting property owners (and managers) with responsible and professional traveling medical professionals. During the BedRN beta, we will prompt you for feedback as you use the platform and may even periodically reach out by email for feedback.</p><p>As a reminder, we will not use the information you entered on the Beta Signup page for any reason other than contacting you once the BedRN beta goes live.</p>"
}

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    marginBottom: theme.spacing(3),
    width: "100%",
    fontWeight: 400
  },
  input: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  bodyText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(3),
  },
}));

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

export default function HomePage(props) {
  const classes = useStyles();

  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [first, setFirst] = useState("");
  const [email, setEmail] = useState("");
  const [hasSignedUp, setHasSignedUp] = useState(false);
  const [snackCtx, setSnackCtx] = useState("info");
  const [snackMsg, setSnackMsg] = useState("");
  const [location, setLocation] = useState(undefined);
  const [isHost, setIsHost] = useState(false)
  const [isTraveler, setIsTraveler] = useState(false)
  const [isRecruiter, setIsRecruiter] = useState(false)

  const checkEmailAddress = (email) =>
    new Promise((resolve, reject) => {
      (async () => {
        try {
          const collectionRef = db.collection("beta-signups");
          const snapshot = await collectionRef
            .where("email", "==", email)
            .get();

          if (snapshot.size > 0) {
            // the user already exists!
            setHasSignedUp(true);
            reject({ message: "User has already signed up!" });
          } else {
            resolve();
          }
        } catch (e) {
          reject(e.message);
        }
      })();
    });

  const handleSignup = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      await checkEmailAddress(email);

      const formValues = {
        first,
        email,
        location,
        isHost,
        isTraveler,
        isRecruiter,
        createdAt: Date.now()
      };

      // Craete the beta signup
      await db.collection("beta-signups").add(formValues);

      // Creat the email record (this will be picked up by a firebase cloud function)
      await db.collection("mail").add({
        to: [email],
        message: emailMessage
      })

      setHasSignedUp(true);
      setSnackCtx("success");
      setSnackMsg("Thank you! We will be in touch.");
    } catch (e) {
      setError(e.message);
    } finally {
      setIsSubmitting(false);
      closeBetaDialog();
    }
  };

  const closeBetaDialog = () => {
    setFirst("");
    setEmail("");
    setDialogIsOpen(false);
  };

  const closeSnack = (e) => {
    setSnackMsg("");
  };

  useEffect(() => {
    console.error(error);
    setSnackCtx("error");
    setSnackMsg(error);
  }, [error]);

  useEffect(() => {
    if ("geolocation" in navigator) {
      // check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
            accuracy: position.coords.accuracy,
            timestamp: position.timestamp,
          });
        },
        function error(error_message) {
          // for when getting location results in an error
          console.error(
            "An error has occured while retrieving location",
            error_message
          );
          setLocation("");
        }
      );
    } else {
      // geolocation is not supported
      // get your location some other way
      setLocation("");
    }
  }, []);

  return (
    <Container maxWidth="md">
      <Typography variant="h2" className={classes.header} align="center">
        Welcome to BedRN
      </Typography>

      <Card>
        <CardContent>
          <Box marginBottom={3}>
            {hasSignedUp && (
              <Typography color="primary">
                Thank you for your interest in the BedRN beta. Please keep an eye
                out for email from us!
              </Typography>
            )}
            <Typography variant="body1" className={classes.bodyText}>
              BedRN is unlike other space listing platforms. We are exclusively
              focused on uniting property owners (and managers) with responsible
              and professional traveling medical professionals. During the BedRN
              beta, we will prompt you for feedback as you use the platform and
              may even periodically reach out by email for feedback. With your
              help, we hope to build BedRN into the ultimate tool for uniting
              hosts and travelers.
            </Typography>

            <Typography variant="body1" className={classes.bodyText}>
              Press the button below to sign up for updates about the BedRN Beta. We will not
              use the information you enter on this form for any reason other
              than contacting you about the BedRN beta.
            </Typography>
          </Box>
        </CardContent>
      </Card>

      <div>
        <Button
          color="primary"
          variant="contained"
          className={classes.button}
          fullWidth
          size="large"
          onClick={() => setDialogIsOpen(true)}
          disabled={hasSignedUp}
        >
          {hasSignedUp ? "Thank you for registering!" : "Join the BedRN beta"}
        </Button>
      </div>

      <Dialog open={dialogIsOpen} onClose={closeBetaDialog}>
        <DialogContent>
          <DialogContentText>
            Thank you for your interest in the BedRN beta! <span role="img">🚀</span>
          </DialogContentText>

          <form onSubmit={handleSignup}>
            <TextField
              autoFocus
              id="firstName"
              label="First name"
              type="text"
              fullWidth
              name="first"
              required={true}
              className={classes.input}
              onChange={(e) => setFirst(e.target.value)}
              value={first}
            />

            <TextField
              id="email"
              label="Email Address"
              type="email"
              fullWidth
              name="email"
              required={true}
              className={classes.input}
              onChange={(e) => setEmail(e.target.value)}
              value={email}
            />

            <FormControl
              component="fieldset"
              className={classes.input}
              required={true}
            >
              <FormLabel component="legend">Are you a</FormLabel>

              <FormGroup>
                <FormControlLabel control={<Checkbox value={isHost} onChange={e => setIsHost(e.target.checked)} />} label="Host" />
                <FormControlLabel control={<Checkbox value={isTraveler} onChange={e => setIsTraveler(e.target.checked)} />} label="Traveler" />
                <FormControlLabel control={<Checkbox value={isRecruiter} onChange={e => setIsRecruiter(e.target.checked)} />} label="Recruiter" />
              </FormGroup>

            </FormControl>

            <Button
              type="submit"
              color="primary"
              variant="contained"
              fullWidth
              disabled={isSubmitting}
              className={classes.button}
            >
              Signup
            </Button>

            <Button
              onClick={closeBetaDialog}
              color="primary"
              size="small"
              className={classes.button}
            >
              Cancel
            </Button>
          </form>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={Boolean(snackMsg)}
        autoHideDuration={6000}
        onClose={closeSnack}
        message={snackMsg}
      >
        <Alert onClose={closeSnack} severity={snackCtx}>
          {snackMsg}
        </Alert>
      </Snackbar>
    </Container>
  );
}
