import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import HomePage from "../scenes/HomePage/HomePage";

import "./App.css";

const images = [
  "https://firebasestorage.googleapis.com/v0/b/bedrn-243904.appspot.com/o/beta-signup-assets%2Fmodern-minimalist-bathroom-3115450.jpg?alt=media&token=24f054d4-4439-4841-b47e-f4226d54b938",
  "https://firebasestorage.googleapis.com/v0/b/bedrn-243904.appspot.com/o/beta-signup-assets%2Fbedroom-490779_1920.jpg?alt=media&token=ec03d811-210b-406e-bef5-13467a63bf5f",
  "https://firebasestorage.googleapis.com/v0/b/bedrn-243904.appspot.com/o/beta-signup-assets%2Fbedroom-1872196_1920.jpg?alt=media&token=506d4888-55a0-4670-ac1a-90c87f66ca07",
  "https://firebasestorage.googleapis.com/v0/b/bedrn-243904.appspot.com/o/beta-signup-assets%2Fbedroom-690129_1920.jpg?alt=media&token=827cdbbc-b84b-4b82-85ca-520f303874a5",
  "https://firebasestorage.googleapis.com/v0/b/bedrn-243904.appspot.com/o/beta-signup-assets%2Fbed-1839183_1920.jpg?alt=media&token=7fc0dd0b-97b2-4dfd-90ff-a481df1851ee",
];

const randomImage = images[Math.floor(Math.random() * images.length)];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundImage: `url(${randomImage})`,
    backgroundSize: "cover",
    width: "100vw",
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
}));

export default function App() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <HomePage />
    </div>
  );
}
