import firebase from "firebase";

const config = {
  apiKey: "AIzaSyCkRNM3FmszQpBP_ckn6fxhuIaAb5FnSBI",
  authDomain: "bedrn-243904.firebaseapp.com",
  databaseURL: "https://bedrn-243904.firebaseio.com",
  projectId: "bedrn-243904",
  storageBucket: "bedrn-243904.appspot.com",
  messagingSenderId: "397654274751",
  appId: "1:397654274751:web:b2b2478974b178c802cc0a",
};

firebase.initializeApp(config);

export const db = firebase.firestore();

export { firebase };
